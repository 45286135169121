<template>
  <div>
    <b-row class="text-center mb-4" align-h="center" v-if="!permissions.read">
      <b-col>
        <h3>You do not have permissions for this page</h3>
      </b-col>
    </b-row>
    <b-row class="text-center mb-4" align-h="center" v-if="loading && permissions.read">
      <b-col>
        <b-container class="text-center">
          <h1>Loading data</h1>
          <h3>... loaded: {{rows.length}} ...</h3>
          <h1><i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i></h1>
        </b-container>
      </b-col>
    </b-row>
    <div v-if="!loading && permissions.read">
        <div>
          <filter-interface
            :pageName="pageName"
            :rows="rows"
            :staticDataProperties="staticDataProperties"
            :staticData="staticData"
            :loading="loading"
            :permissions="permissions"
            :perPage="perPage"
          />
        </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import ac from '@/libs/accesscontrol'

import FilterInterface from '@/components/FilterInterface.vue'

export default {
  name: 'Case',
  components: {
    FilterInterface
  },
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: function () {
    this.$stat.log({ page: 'open case', action: 'open case' })
    this.permissions.read = ac.can(this.user.acgroups).readAny('case').granted
    this.permissions.add = ac.can(this.user.acgroups).createAny('case').granted
    if (this.permissions.read) {
      this.load()
    }
  },
  data () {
    return {
      pageName: 'Cases',
      permissions: {
        add: false,
        read: false
      },
      staticDataProperties: [
        { name: 'Coverage', property: 'coverage', graph: 'coverage_id' },
        { name: 'Product Types', property: 'producttype', graph: 'producttypes' },
        { name: 'Asset Class', property: 'assetclass', graph: 'assetclasses' },
        { name: 'Control risks', property: 'controlrisk', graph: 'controlrisks' },
        { name: 'MAR risks', property: 'risk', graph: 'risks' }
      ],
      loading: true,
      rows: [],
      staticData: null,
      perPage: 20
    }
  },
  methods: {
    load: async function () {
      this.loading = true
      try {
        const limit = 20
        let offset = 0
        let response = []
        let page = 0
        do {
          offset = page * limit
          response = await this.$Amplify.API.get('cosmos', `/cases/limit/${limit}/offset/${offset}`)
          const data = _.concat(this.rows, response)
          this.rows = data
          page++
          await this.$nextTick()
        } while (response.length === limit)
        this.staticData = await this.$Amplify.API.get('cosmos', '/cases/staticdata')
        this.loading = false
      } catch (e) {
        this.$logger.warn('saved ERROR: ', e)
      }
    }
  }
}
</script>

<style>
</style>
